var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = "/monitoring";
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"2d8396731ba07b70d6147aafe6e0fadd3ae9226f"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import { init, getReplay, replayIntegration, captureConsoleIntegration } from '@sentry/nextjs';
import { ErrorEvent, Event } from '@sentry/types';

const exclude = ["Failed to execute 'postMessage'", 'The play() request was interrupted'];

init({
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,

  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: Number('1'),

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,

  replaysOnErrorSampleRate: 0,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0,

  beforeSend: async (event: ErrorEvent): Promise<Event> => {
    if (exclude.some((value) => event?.message?.includes(value))) {
      return {};
    }
    return event;
  },

  // You can remove this option if you're not planning to use the Sentry Session Replay feature:
  integrations: [
    captureConsoleIntegration({
      levels: ['error'],
    }),
    replayIntegration({
      maskAllText: false,
      blockAllMedia: true,
      beforeErrorSampling: (error: ErrorEvent): boolean => {
        if (exclude.some((value) => error?.message?.includes(value))) {
          return false;
        }
        return true;
      },
    }),
  ],
});

const replay = getReplay();
if (replay) {
  replay.startBuffering();
}
